export const AUCTIONS_EXAMPLE = 'auctionsExample';
export const AUCTION_DETAILS = 'auctionDetails';
export const AUCTION_BIDS = 'AUCTION_BIDS';
export const ACTIVE_PRODUCT = 'ACTIVE_PRODUCT';
export const STATUS_TYPE = 'STATUS_TYPE';
export const INSURANCE_MODAL = 'INSURANCE_MODAL';
export const TRANSFER_INSURANCE_MODAL = 'TRANSFER_INSURANCE_MODAL';
export const INSURANCE_CURRENT_AUCTION = 'INSURANCE_CURRENT_AUCTION';
export const HIGHEST_BID = 'HIGHEST_BID';
export const BID_INCREMENT = 'BID_INCREMENT';
export const START_PRICE = 'START_PRICE';
